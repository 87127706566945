import React from 'react';
import Login from './login'

const App = (props) => {

    return (
        <Login />
    )
}

export default App

import React, {useState} from 'react';
import {getRoute, postRoute} from './api'
import {
    Grid, Typography, Box, Paper, TextField, InputAdornment,
    Button, Stack, Alert
} from '@mui/material/';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import KeyIcon from '@mui/icons-material/Key';
import bacologo from './ballerstaedt_logo.png'

const Login = (props) => {
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [authfailed, setAuthfailed] = useState(false)

    const startLogin = () => {
        const data = {
            'email': email,
            'pass': pass
        }
        
        postRoute('login', data).then((data) => {
            if(data && !data.success) {
                setAuthfailed(true)
            } else {
                const token = data.token
                setAuthfailed(false)
                localStorage.setItem('token', token)
                
                // getRoute('home', token)
                window.location = '/crmmesse'
                
            }
        })
    }

    return (
        <Grid sx={{height:'100%',overflowY:'auto',bgcolor:'#f8fafc'}} container justifyContent="center" alignItems="center">
            <Grid sx={{p:2}} item xs={12} sm={8} md={8} lg={4}>

                <Paper sx={{px:2,py:4}}>
                    <Grid container justifyContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <Box 
                            component="img"
                            sx={{width:'250px'}}
                            src={bacologo} 
                        />
                    </Grid>
                    <Grid sx={{my:4}} item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="center" color="text.secondary" variant="h6">
                            BacoTools Login
                        </Typography>
                    </Grid>
                    {/* Wenn Login fehlgeschlagen, dann authfailed=true */}
                    {authfailed && 
                        <Grid sx={{my:2}} container justifyContent="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={12} sm={10} md={10} lg={7}>
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="error">Login fehlgeschlagen, Benutzername oder Passwort nicht korrekt.</Alert>
                                </Stack>
                            </Grid>
                        </Grid>
                    }
                    <Grid sx={{my:2}} container justifyContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={10} md={10} lg={7}>
                            <TextField
                                fullWidth
                                id="input_username"
                                label="Emailadresse"
                                size="small"
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                ),
                                }}
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{my:2}} container justifyContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={10} md={10} lg={7}>
                            <TextField
                                fullWidth
                                id="input_pass"
                                label="Passwort"
                                type="password"
                                size="small"
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                ),
                                }}
                                variant="outlined"
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{mt:3}} container justifyContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={10} md={10} lg={7}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!email || !pass}
                                color="primary"
                                size="large"
                                onClick={startLogin}
                            >
                                Anmelden
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

            </Grid>
        </Grid>
    )
}

export default Login
export async function postRoute(apiname, data) { 
    try {
        let response = await fetch(`/${apiname}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return await response.json()
    } catch(err) {
        console.log(err)
    }
}

export async function getRoute(apiname, token) {
    try {
        fetch(`/${apiname}`, {
            method: 'GET',
            headers: {
                'authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(err) {
        console.log(err)
    }
}